@media only screen and (max-width: 1100px) {
   .right-container {
      width: 0vw !important;
   }
   .left-container {
      width: 100vw !important;
   }
   .auth-img {
      display: none;
   }
}



