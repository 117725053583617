.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}
.group-margin-top {
  margin-top: 15px;
}
.button-text-size {
  font-size: 34;
}

/* customized scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 4.5px;
}

::-webkit-scrollbar-track {
  background-color: #f2f2f2;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  background-color: #467534;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #408f4a;
}

.ant-table-tbody > tr > td {
  height: 1rem !important;
  padding: 0.25rem 0.5rem !important;
}
